const lightTheme = {
  type: 'light',
  // colors
  primaryFontColor: '#7d7d7d', /* primaryFontColor: '#08080B', */
  primaryLightFontColor: '#981b1e',
  toastFontColor: 'black',
  secondaryFontColor: '#F85D0F',
  inverseFontColor: 'white',
  inverseButtonFontColor: 'white',
  highlight: '#a0a3a2',
  borderColor: 'rgba(8,8,11,0.15)',
  backgroundColor: '#fafafa',
  secondaryBackgroundColor: '#ffffff',
  codeBackgroundColor: '#ededed',
  toastBackgroundColor: 'white',
  // font families
  fontFamily: '""Circular Pro",Verdana,sans-serif"',
  //fontFamily: '"SF Pro Display", -apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif',
  scriptFamily: 'EB Garamond',
  baseFontWeight: '700',
}

export default lightTheme