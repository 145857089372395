                    
                                                                                                     

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:a441fb2a-e181-4668-84cc-8aed2ac92acc",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_dz8ZVxDmJ",
    "aws_user_pools_web_client_id": "7sg061uu2gp31v2gk3u8aubqag",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://ylwcprrh3zfpbjemj7torles7q.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "jamstack-cms-e8f2dd73-b56b-4d58-b237-51547de0ee1a-prod",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


module.exports = awsmobile;
